import React, { useState, useEffect } from "react";
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function SolIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>       
            <path d="M3.57430068,13.158837 C3.70705061,13.0260871 3.88958177,12.9486496 4.08317542,12.9486496 L21.6393539,12.9486496 C21.9601663,12.9486496 22.1205724,13.3358369 21.8937913,13.5626181 L18.4256993,17.03071 C18.2929494,17.16346 18.1104182,17.2408974 17.9168246,17.2408974 L0.36064607,17.2408974 C0.0398337337,17.2408974 -0.120572435,16.8537101 0.1062087,16.626929 L3.57430068,13.158837 Z"></path>
            <path d="M3.57430068,0.210187393 C3.71258186,0.0774374604 3.89511302,0 4.08317542,0 L21.6393539,0 C21.9601663,0 22.1205724,0.387187302 21.8937913,0.613968436 L18.4256993,4.08206041 C18.2929494,4.21481035 18.1104182,4.29224781 17.9168246,4.29224781 L0.36064607,4.29224781 C0.0398337337,4.29224781 -0.120572435,3.9050605 0.1062087,3.67827937 L3.57430068,0.210187393 Z"></path>
            <path d="M18.4256993,6.64302785 C18.2929494,6.51027792 18.1104182,6.43284046 17.9168246,6.43284046 L0.36064607,6.43284046 C0.0398337337,6.43284046 -0.120572435,6.82002776 0.1062087,7.0468089 L3.57430068,10.5149009 C3.70705061,10.6476508 3.88958177,10.7250883 4.08317542,10.7250883 L21.6393539,10.7250883 C21.9601663,10.7250883 22.1205724,10.337901 21.8937913,10.1111198 L18.4256993,6.64302785 Z"></path>
        </SvgIcon>
    );
  }