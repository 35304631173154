import React, { useCallback, useContext, useEffect, useState } from "react";
import { AccountInfo, ConfirmedSignatureInfo, ConfirmedTransaction, Connection, PublicKey } from "@solana/web3.js";
import { AccountLayout, MintInfo, MintLayout } from "@solana/spl-token";
//import { u64 } from '../../../utils/u64';
//import { u64 } from '@project-serum/borsh';
import BN from 'bn.js'
import { TokenAccount } from "../models/account";
import { EventEmitter } from "../helpers/eventEmitter";
import { programIds, WRAPPED_SOL_MINT } from "../helpers/ids";
import { useWallet } from "@solana/wallet-adapter-react";
import { chunks } from "../helpers/common";
import { useMeanFiConnection } from "./appstate";

const AccountsContext = React.createContext<any>(null);

const pendingCalls = new Map<string, Promise<ParsedAccountBase>>();
const genericCache = new Map<string, ParsedAccountBase>();
const transactionCache = new Map<string, ParsedLocalTransaction | null>();

export interface ParsedLocalTransaction {
  transactionType: number;
  signature: ConfirmedSignatureInfo;
  confirmedTx: ConfirmedTransaction | null;
}

export interface ParsedAccountBase {
  pubkey: PublicKey;
  account: AccountInfo<Buffer>;
  info: any;
}

export type AccountParser = (
  pubkey: PublicKey,
  data: AccountInfo<Buffer>
) => ParsedAccountBase | undefined;

export interface ParsedAccount<T> extends ParsedAccountBase {
  info: T;
}

export const MintParser = (pubKey: PublicKey, info: AccountInfo<Buffer>) => {
  const buffer = Buffer.from(info.data);

  const data = deserializeMint(buffer);

  const details = {
    pubkey: pubKey,
    account: {
      ...info,
    },
    info: data,
  } as ParsedAccountBase;

  return details;
};

export const TokenAccountParser = (
  pubKey: PublicKey,
  info: AccountInfo<Buffer>
) => {
  const buffer = Buffer.from(info.data);
  const data = deserializeAccount(buffer);

  const details = {
    pubkey: pubKey,
    account: {
      ...info,
    },
    info: data,
  } as TokenAccount;

  return details;
};

export const GenericAccountParser = (
  pubKey: PublicKey,
  info: AccountInfo<Buffer>
) => {
  const buffer = Buffer.from(info.data);

  const details = {
    pubkey: pubKey,
    account: {
      ...info,
    },
    info: buffer,
  } as ParsedAccountBase;

  return details;
};

export const keyToAccountParser = new Map<string, AccountParser>();

export const cache = {
  emitter: new EventEmitter(),
  query: async (
    connection: Connection,
    pubKey: string | PublicKey,
    parser?: AccountParser
  ) => {
    let id: PublicKey;
    if (typeof pubKey === "string") {
      id = new PublicKey(pubKey);
    } else {
      id = pubKey;
    }

    const address = id.toBase58();

    let account = genericCache.get(address);
    if (account) {
      return account;
    }

    let query = pendingCalls.get(address);
    if (query) {
      return query;
    }
    console.log("address: "+JSON.stringify(address));

    query = connection.getAccountInfo(id).then((data) => {
      
      if (!data) {
        throw new Error("Account not found");
      }

      return cache.add(id, data, parser);
    }) as Promise<TokenAccount>;
    pendingCalls.set(address, query as any);

    return query;
  },
  add: (
    id: PublicKey | string,
    obj: AccountInfo<Buffer>,
    parser?: AccountParser
  ) => {
    if (obj.data.length === 0) {
      return;
    }

    const address = typeof id === "string" ? id : id?.toBase58();
    const deserialize = parser ? parser : keyToAccountParser.get(address);
    if (!deserialize) {
      throw new Error(
        "Deserializer needs to be registered or passed as a parameter"
      );
    }

    cache.registerParser(id, deserialize);
    pendingCalls.delete(address);
    const account = deserialize(new PublicKey(address), obj);
    if (!account) {
      return;
    }

    const isNew = !genericCache.has(address);

    genericCache.set(address, account);
    cache.emitter.raiseCacheUpdated(address, isNew, deserialize);
    return account;
  },
  get: (pubKey: string | PublicKey) => {
    let key: string;
    if (typeof pubKey !== "string") {
      key = pubKey.toBase58();
    } else {
      key = pubKey;
    }

    return genericCache.get(key);
  },
  delete: (pubKey: string | PublicKey) => {
    let key: string;
    if (typeof pubKey !== "string") {
      key = pubKey.toBase58();
    } else {
      key = pubKey;
    }

    if (genericCache.get(key)) {
      genericCache.delete(key);
      cache.emitter.raiseCacheDeleted(key);
      return true;
    }
    return false;
  },

  byParser: (parser: AccountParser) => {
    const result: string[] = [];
    for (const id of keyToAccountParser.keys()) {
      if (keyToAccountParser.get(id) === parser) {
        result.push(id);
      }
    }

    return result;
  },
  registerParser: (pubkey: PublicKey | string, parser: AccountParser) => {
    if (pubkey) {
      const address = typeof pubkey === "string" ? pubkey : pubkey?.toBase58();
      keyToAccountParser.set(address, parser);
    }

    return pubkey;
  },
  addTransaction: (signature: string, tx: ParsedLocalTransaction | null) => {
    transactionCache.set(signature, tx);
    return tx;
  },
  addBulkTransactions: (txs: Array<ParsedLocalTransaction>) => {
    for (const tx of txs) {
      transactionCache.set(tx.signature.signature, tx);
    }
    return txs;
  },
  getTransaction: (signature: string) => {
    const transaction = transactionCache.get(signature);
    return transaction;
  },
  getAllTransactions: () => {
    return transactionCache;
  },
  clear: () => {
    genericCache.clear();
    transactionCache.clear();
    cache.emitter.raiseCacheCleared();
  },
};

export const useAccountsContext = () => {
  const context = useContext(AccountsContext);
  return context;
};

function wrapNativeAccount(
  pubkey: PublicKey,
  account?: AccountInfo<Buffer>
): TokenAccount | undefined {
  if (!account) {
    return undefined;
  }

  return {
    pubkey: pubkey,
    account,
    info: {
      address: pubkey,
      mint: WRAPPED_SOL_MINT,
      owner: pubkey,
      amount: new BN(account.lamports || 0),//new u64(account.lamports || 0),
      delegate: null,
      delegatedAmount: new BN(0),//new u64(0),
      isInitialized: true,
      isFrozen: false,
      isNative: true,
      rentExemptReserve: null,
      closeAuthority: null,
    },
  };
}

const UseNativeAccount = () => {
  const { connection } = useMeanFiConnection();
  const { wallet, publicKey } = useWallet();
  const [nativeAccount, setNativeAccount] = useState<AccountInfo<Buffer>>();

  const updateCache = useCallback(
    (account) => {
      if (!connection || !publicKey) {
        return;
      }

      const wrapped = wrapNativeAccount(publicKey, account);

      if (wrapped !== undefined) {
        const id = publicKey.toBase58();
        cache.registerParser(id, TokenAccountParser);
        genericCache.set(id, wrapped as TokenAccount);
        cache.emitter.raiseCacheUpdated(id, false, TokenAccountParser);
      }
    },
    [publicKey, connection]
  );

  useEffect(() => {
    if (!connection || !publicKey) {
      return;
    }

    connection.getAccountInfo(publicKey).then((acc) => {
      if (acc) {
        updateCache(acc);
        setNativeAccount(acc);
      } else {
        updateCache(undefined);
        setNativeAccount(undefined);
      }
    })
    .catch(error => {
      throw(error);
    });
    const listener = connection.onAccountChange(publicKey, (acc) => {
      if (acc) {
        updateCache(acc);
        setNativeAccount(acc);
      }
    });

    return () => {
      if (listener) {
        connection.removeAccountChangeListener(listener);
      }
    };
  }, [
    wallet,
    publicKey,
    connection,
    setNativeAccount,
    updateCache,
  ]);

  return { nativeAccount };
};

const PRECACHED_OWNERS = new Set<string>();
const precacheUserTokenAccounts = async (
  connection: Connection,
  owner?: PublicKey
) => {
  if (!owner) {
    return;
  }

  // used for filtering account updates over websocket
  PRECACHED_OWNERS.add(owner.toBase58());

  // user accounts are update via ws subscription
  try {
    const accounts = await connection.getTokenAccountsByOwner(owner, {
      programId: programIds().token,
    });
    accounts.value.forEach((info) => {
      cache.add(info.pubkey.toBase58(), info.account, TokenAccountParser);
    });
  } catch (error) {
    console.log('getTokenAccountsByOwner failed.', error);
    throw(error);
  }
};

export function AccountsProvider({ children = null as any }) {
  const { connection } = useMeanFiConnection();
  const { publicKey, wallet, connected } = useWallet();
  const [tokenAccounts, setTokenAccounts] = useState<TokenAccount[]>([]);
  const [userAccounts, setUserAccounts] = useState<TokenAccount[]>([]);
  const { nativeAccount } = UseNativeAccount();

  const selectUserAccounts = useCallback(() => {
    if (!publicKey) {
      return [];
    }

    const address = publicKey.toBase58();

    return cache
      .byParser(TokenAccountParser)
      .map((id) => cache.get(id))
      .filter((a) => a && a.info.owner.toBase58() === address)
      .map((a) => a as TokenAccount);
  }, [publicKey]);

  useEffect(() => {
    const accounts = selectUserAccounts().filter(
      (a) => a !== undefined
    ) as TokenAccount[];
    setUserAccounts(accounts);
  }, [nativeAccount, wallet, tokenAccounts, selectUserAccounts]);

  useEffect(() => {
    const subs: number[] = [];
    cache.emitter.onCache((args) => {
      if (args.isNew) {
        let id = args.id;
        let deserialize = args.parser;
        const listenerId = connection.onAccountChange(new PublicKey(id), (info) => {
          cache.add(id, info, deserialize);
        });
        subs.push(listenerId);
      }
    });

    return () => {
      subs.forEach((id) => connection.removeAccountChangeListener(id));
    };
  }, [connection]);

  useEffect(() => {
    if (!connection || !publicKey) {
      setTokenAccounts([]);
    } else {
      precacheUserTokenAccounts(connection, publicKey).then(() => {
        setTokenAccounts(selectUserAccounts());
      });

      const tokenSubID = connection.onProgramAccountChange(
        programIds().token,
        (info) => {
          const id = (info.accountId as unknown) as string;
          if (info.accountInfo.data.length === AccountLayout.span) {
            const data = deserializeAccount(info.accountInfo.data);

            if (PRECACHED_OWNERS.has(data.owner.toBase58())) {
              cache.add(id, info.accountInfo, TokenAccountParser);
              setTokenAccounts(selectUserAccounts());
            }
          }
        },
        "singleGossip"
      );

      return () => {
        connection.removeProgramAccountChangeListener(tokenSubID);
      };
    }
  }, [connection, connected, publicKey, selectUserAccounts]);

  return (
    <AccountsContext.Provider
      value={{
        userAccounts,
        nativeAccount,
        tokenAccounts
      }}>
      {children}
    </AccountsContext.Provider>
  );
}

export function useNativeAccount() {
  const context = useContext(AccountsContext);
  return {
    account: context.nativeAccount as AccountInfo<Buffer>,
  };
}

export const getMultipleAccounts = async (
  connection: any,
  keys: string[],
  commitment: string
) => {
  
  const result = await Promise.all(
    chunks(keys, 99).map((chunk) =>
      getMultipleAccountsCore(connection, chunk, commitment)
    )
  );

  const array = result
    .map(
      (a) =>
        a.array
          .map((acc) => {
            if (!acc) {
              return undefined;
            }

            const { data, ...rest } = acc;
            const obj = {
              ...rest,
              data: Buffer.from(data[0], "base64"),
            } as AccountInfo<Buffer>;
            return obj;
          })
          .filter((_) => _) as AccountInfo<Buffer>[]
    )
    .flat();
  return { keys, array };
};

const getMultipleAccountsCore = async (
  connection: any,
  keys: string[],
  commitment: string
) => {

  const args = connection._buildArgs([keys], commitment, "base64");
  const unsafeRes = await connection._rpcRequest("getMultipleAccounts", args);
  
  if (unsafeRes.error) {
    throw new Error(
      "failed to get info about account " + unsafeRes.error.message
    );
  }

  if (unsafeRes.result.value) {
    const array = unsafeRes.result.value as AccountInfo<string[]>[];
    return { keys, array };
  }

  throw new Error();
};

export function useMint(key?: string | PublicKey) {
  const { connection } = useMeanFiConnection();
  const [mint, setMint] = useState<MintInfo>();
  
  const id = typeof key === "string" ? key : key?.toBase58();
  
  useEffect(() => {
    if (!id) {
      return;
    }
    cache
      .query(connection, id, MintParser)
      .then((acc) => setMint(acc.info as any))
      .catch((err) => console.error(err));
    
    const dispose = cache.emitter.onCache((e) => {
      const event = e;
      if (event.id === id) {
        cache
          .query(connection, id, MintParser)
          .then((mint) => setMint(mint.info as any));
      }
    });
    return () => {
      dispose();
    };
  }, [connection, id]);
  return mint;
}

export const useAccountByMint = (mint: string) => {
  const { userAccounts } = useUserAccounts();
  const index = userAccounts.findIndex(
    (acc) => acc.info.mint.toBase58() === mint
  );

  if (index !== -1) {
    return userAccounts[index];
  }

  return;
};

export function useAccount(pubKey?: PublicKey) {
  const { connection } = useMeanFiConnection();
  const [account, setAccount] = useState<TokenAccount>();

  const key = pubKey?.toBase58();
  useEffect(() => {
    const query = async () => {
      try {
        if (!key) {
          return;
        }

        const acc = await cache
          .query(connection, key, TokenAccountParser)
          .catch((err) => console.error(err));
        if (acc) {
          setAccount(acc);
        } else {
          setAccount(undefined);
        }
      } catch (err) {
        console.error(err);
      }
    };

    query();

    const dispose = cache.emitter.onCache((e) => {
      const event = e;
      if (event.id === key) {
        query();
      }
    });
    return () => {
      dispose();
    };
  }, [connection, key]);

  return account;
}

const deserializeAccount = (data: Buffer) => {
  const accountInfo = AccountLayout.decode(data);
  accountInfo.mint = new PublicKey(accountInfo.mint);
  accountInfo.owner = new PublicKey(accountInfo.owner);
  accountInfo.amount = new BN(accountInfo.amount);//u64.fromBuffer(accountInfo.amount);

  if (accountInfo.delegateOption === 0) {
    accountInfo.delegate = null;
    accountInfo.delegatedAmount = new BN(0);//new u64(0);
  } else {
    accountInfo.delegate = new PublicKey(accountInfo.delegate);
    accountInfo.delegatedAmount = new BN(accountInfo.delegatedAmount);//u64.fromBuffer(accountInfo.delegatedAmount);
  }

  accountInfo.isInitialized = accountInfo.state !== 0;
  accountInfo.isFrozen = accountInfo.state === 2;

  if (accountInfo.isNativeOption === 1) {
    accountInfo.rentExemptReserve = new BN(accountInfo.isNative);//u64.fromBuffer(accountInfo.isNative);
    accountInfo.isNative = true;
  } else {
    accountInfo.rentExemptReserve = null;
    accountInfo.isNative = false;
  }

  if (accountInfo.closeAuthorityOption === 0) {
    accountInfo.closeAuthority = null;
  } else {
    accountInfo.closeAuthority = new PublicKey(accountInfo.closeAuthority);
  }

  return accountInfo;
};

export const deserializeMint = (data: Buffer) => {
  if (data.length !== MintLayout.span) {
    throw new Error("Not a valid Mint");
  }

  const mintInfo = MintLayout.decode(data);

  if (mintInfo.mintAuthorityOption === 0) {
    mintInfo.mintAuthority = null;
  } else {
    mintInfo.mintAuthority = new PublicKey(mintInfo.mintAuthority);
  }

  mintInfo.supply = new BN(mintInfo.supply);//u64.fromBuffer(mintInfo.supply);
  mintInfo.isInitialized = mintInfo.isInitialized !== 0;

  if (mintInfo.freezeAuthorityOption === 0) {
    mintInfo.freezeAuthority = null;
  } else {
    mintInfo.freezeAuthority = new PublicKey(mintInfo.freezeAuthority);
  }

  return mintInfo as MintInfo;
};

export function useUserAccounts() {
  const context = useAccountsContext();
  return {
    userAccounts: context.userAccounts as TokenAccount[],
  };
}
